@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Poppins&family=Gelasio&display=swap'); 
@import "~react-image-gallery/styles/css/image-gallery.css";

html,body{
  min-height:100%;
  width:100%;
  padding:0;
  margin:0;
  
}

body {
  margin: 0;
  font-family:Poppins !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 0px) {
  body{
    font-size:13px;
  }

  .contentWrapper{
    width:100%;
  }

  .productCard:first-of-type{
    border-top:1px solid #F0F0F0;
  }

  .footer{
    width:100%;  
  }
}

@media (min-width: 600px) {

}

@media (min-width: 900px) {
  .contentWrapper{
    width:calc(85% - 80px);
    padding:40px;
    margin:0 auto;
  }
  .footer{
    width:calc(90% - 80px);  
    padding:40px;
    margin:0 auto;
  }

  .productCard{
    border:1px solid #F0F0F0;
  }
}

@media (min-width: 1200px) {
  body{
    font-size:15px;
  }
}

@media (min-width: 1536px) {
  
}


.wrapper{
  min-height:100%;
  width:100%;
  margin:0;
  padding:0;
  position:relative;
}

.header{
  height:auto;
  background:#000000;
  color:#FFFFFF;

}

.mainImage{
  min-height:900px;
  height:100%;
  width:100%;
  /* background-image: url("../../public/images/test2.jpg"); */
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left top;
}

.image-gallery-slide img {
  width:100%;
  height: auto !important;
  max-height:500px !important;
  margin-top:0;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.contentWrapper{
  margin-top:0;
  height:100%;
  min-height:300px
}

.contentWrapperPadded{
  width:calc(90% - 40px);
  padding:40px 20px;
  margin:0 auto;
  height:calc(100% - 80px);
  min-height:300px
}


.productCard{
  border-radius:3px;
  width:calc(100%-60px); 
  padding:30px;
  height:auto;
  position:relative;
  box-sizing: border-box;
  text-align: center;
  height:100%;
  color:#787878;
}


.productCard .title{
  color: #989898;
  font-weight:500;
  font-size:1.05em;
}

.productCard .price{
  color: #787878;
  font-weight:600;
  font-size:1.05em;
}



.productCard img{
  max-width: 100%;  
  max-height:250px;
  margin-bottom:0;
  object-fit: fill; 
}


.image-gallery-slide img {
  width: 100%;
  height: auto !important;
  object-fit: cover;
  overflow: hidden;
  margin:0 auto;
  object-position: center center;
}


.sage{
  background:transparent !important;
  color:#a6b396 !important;
  font-weight:500 !important;
  font-size:.9em !important;
  border:2px solid #a6b396 !important;
  border-radius:25px !important;
  width:auto !important;
  margin-top:20px !important;
  padding: 5px 20px !important;
  text-decoration:none;
}

.sage:hover{
  background:#a6b396 !important;
  color:#FFFFFF !important;
  text-decoration:none;
}

.navLink{
  /* background:#e9e5d5 !important; */
  
  color:#383838 !important; 
  border-radius:0px !important; 
  border:0 !important;
  font-size:1em !important; 
  margin-bottom:30px;
  padding: 10px 20px !important;
  font-family:Poppins !important;
}

.navLink:hover{
  background:transparent !important;
}

.catLink{
  color:#383838 !important; 
  font-size:1em !important; 
  padding: 0px !important;
  font-family:Poppins !important;
  margin:0 !important;
  width:90% !important;
  justify-content: left !important;
  text-align:left !important;
  padding:5px 0px !important;

}

.catLink:hover{
  background:transparent !important;
}


.bannerBtn{
  color:#383838 !important; 
  border-radius:0px !important; 
  border:0 !important;
  font-size:1em !important; 
  padding: 10px 20px !important; 
}

.banner{
  font-family:Gelasio !important;
  position:relative;
}

.footerWrapper{
  width:100%;
  /* background:rgb(233,229,213,.40); */
}

.footer{
  font-size:.9em;
  border-top:1px solid #f0f0f0;
}